import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState('purple-theme'); // Default theme
  const [themeMenuVisible, setThemeMenuVisible] = useState(false);

  // Apply the current theme to the document body
  useEffect(() => {
    document.body.className = currentTheme;
  }, [currentTheme]);

  const changeTheme = (theme) => {
    setCurrentTheme(theme);
  };

  const toggleThemeMenu = () => {
    setThemeMenuVisible((prev) => !prev);
  };

  const closeThemeMenu = () => {
    setThemeMenuVisible(false);
  };

  return (
    <ThemeContext.Provider
      value={{
        currentTheme,
        changeTheme,
        themeMenuVisible,
        toggleThemeMenu,
        closeThemeMenu,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};