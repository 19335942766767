import React, { useContext } from 'react';
import './header.css';
import CTA from './CTA';
import HeaderSocials from './headerSocials';
import { ThemeContext } from '../theme/context/ThemeContext';

const Header = () => {
  const { toggleThemeMenu } = useContext(ThemeContext);

  return (
    <header>
      <div className="container header__container">
        <div className="header_text">
          <h5>Hello I'm</h5>
          <h1>Mohammad Tanzeel Ur Rehman</h1>
          <h5 className="text-light">Software Developer</h5>
          <CTA />
        </div>
        <HeaderSocials />
        <a href="#about" className="scroll__down">Scroll Down</a>
        <a className="nav-toggle" onClick={toggleThemeMenu}>Change Theme</a>
      </div>
    </header>
  );
};

export default Header;