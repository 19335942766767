import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import './nav.css';
import { BiHomeAlt2, BiUser, BiPaint } from 'react-icons/bi';
import { BsBookmarkStar, BsChatRight } from 'react-icons/bs';
import { RiServiceLine } from 'react-icons/ri';
import { ThemeContext } from '../theme/context/ThemeContext';

const Nav = () => {
  const { themeMenuVisible, toggleThemeMenu } = useContext(ThemeContext);
  const [isVisible, setIsVisible] = useState(true);
  const [activeNav, setActiveNav] = useState('#');

  useEffect(() => {
    let timeout;

    const handleMouseActivity = () => {
      setIsVisible(true);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsVisible(false);
      }, 3500); // Adjust the delay as needed
    };

    const handleMouseLeave = () => {
      clearTimeout(timeout);
      setIsVisible(false);
    };

    const handleScroll = () => {
      handleMouseActivity();
    };

    window.addEventListener('mousemove', handleMouseActivity);
    window.addEventListener('mouseleave', handleMouseLeave);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('mousemove', handleMouseActivity);
      window.removeEventListener('mouseleave', handleMouseLeave);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavClick = (navItem) => {
    if (navItem === 'theme') {
      toggleThemeMenu(); // Toggle the theme menu visibility
    } else {
      setActiveNav(navItem); // Set active navigation item
    }
  };

  return (
    <nav className={`nav__items ${isVisible ? 'visible' : 'hidden'}`}>
      <a href="#" onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : 'inactive'}><BiHomeAlt2 /></a>
      <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><BiUser /></a>
      <a href="#experience" onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}><BsBookmarkStar /></a>
      <a href="#services" onClick={() => setActiveNav('#services')} className={activeNav === '#services' ? 'active' : ''}><RiServiceLine /></a>
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><BsChatRight /></a>
      <a onClick={() => handleNavClick('theme')} className={themeMenuVisible ? 'active' : ''}><BiPaint /></a>
    </nav>
  );
};

export default Nav;