import React, { useContext } from 'react';
import './theme.css';
import { ThemeContext } from './context/ThemeContext';

const Theme = ({ onThemeSelect = () => {} }) => {
  const { changeTheme } = useContext(ThemeContext);

  const handleThemeClick = (theme) => {
    changeTheme(theme);
    onThemeSelect(); // Close the theme menu if provided
  };

  return (
    <nav className="theme_items">
      <h4 className='theme_items_h5'>Select Theme</h4>
      <a onClick={() => handleThemeClick('purple-theme')}>Wisteria Purple</a>
      <a onClick={() => handleThemeClick('green-theme')}>Forest Green</a>
      <a onClick={() => handleThemeClick('blue-theme')}>Midnight Blue</a>
      <a onClick={() => handleThemeClick('gray-theme')}>Charcoal Gray</a>
    </nav>
  );
};

export default Theme;