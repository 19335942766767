import React, {useContext} from 'react';
import { ThemeContext } from './components/theme/context/ThemeContext';
import Header from './components/header/Header';
import Nav from './components/nav/Nav';
import About from './components/about/About';
import Experience from './components/experience/Experience';
import Portfolio from './components/portfolio/Portfolio';
import Services from './components/services/Services';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import Theme from './components/theme/Theme';

const App = () => {
  const { themeMenuVisible, closeThemeMenu } = useContext(ThemeContext);

  return (
    <>
      <Header />
      <Nav />
      <About />
      <Experience />
      <Services />
      <Portfolio />
      <Contact />
      <Footer />
      {/* Centralized Theme Menu */}
      {themeMenuVisible && <Theme onThemeSelect={closeThemeMenu} />}
    </>
  );
};

export default App;